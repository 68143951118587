




import Vue from 'vue'
import UserForm from '@/views/User/views/UserForm.vue'

export default Vue.extend({
  name: 'ShowUser',
  components: { UserForm },
  data: function (): { id: string } {
    const id = 'id' in this.$route.params && this.$route.params.id
    if (!id) {
      throw new Error('ShowClient is missing required route param id')
    }
    return { id }
  },
})
